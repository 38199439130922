import React from 'react'

const Blog = () => {
  return (
    <div className='container mx-auto'>
      <h1>This is Blog Page</h1>
    </div>
  )
}

export default Blog
