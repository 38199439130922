export const navBarData = [
    {
        title: "Home",
        url: "/"
    },
    {
        title: "About Us",
        url: "/about"
    },
    // {
    //     title: "Hotels",
    //     url: "/hotels"
    // },
    {
        title: "Services",
        url: "/services"
    },
    // {
    //     title: "Blog",
    //     url: "/blog"
        
    // },
    // {
    //     title: 	'Blog',
    //     url: '/blog'
    // },
    {
        title: "Contact",
        url: "/contact"
    }
]