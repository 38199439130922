import React from 'react';

const Services = () => {
  return (
    <div className='container mx-auto px-4 py-12'>
      <h1 className='text-4xl font-bold text-headingText mb-4'>Our Services</h1>
      <p className='text-lg text-primaryText mb-8'>
        At our company, we offer a range of high-quality services designed to meet the needs of healthcare providers. Our dedicated team of qualified medical professionals is here to support you every step of the way.
      </p>

      <section className='my-12 p-8 bg-white rounded-lg shadow-md'>
        <h2 className='text-3xl font-bold mb-4 text-headingText'>What We Offer</h2>
        <ul className='list-disc list-inside text-lg text-primaryText space-y-4'>
          <li>
            <strong>Medical Credentialing:</strong> We help you navigate the complex credentialing process to ensure you are eligible for the panels you want.
          </li>
          <li>
            <strong>Billing Services:</strong> Our billing team ensures that you get maximum reimbursement for your services while minimizing your administrative burden.
          </li>
          <li>
            <strong>Telemedicine Solutions:</strong> We provide support for telemedicine services, enabling you to deliver care remotely.
          </li>
          <li>
            <strong>Patient Relationship Management:</strong> Enhance your patient interactions with our tailored solutions that foster better communication and satisfaction.
          </li>
        </ul>
      </section>

      <section className='my-12 p-8 bg-white rounded-lg shadow-md'>
        <h2 className='text-3xl font-bold mb-4 text-headingText'>Why Choose Us?</h2>
        <p className='text-lg text-primaryText mb-4'>
          Our commitment to excellence is reflected in our client satisfaction. We strive to build long-lasting partnerships with healthcare providers by understanding their unique needs and delivering tailored solutions.
        </p>
        <ul className='list-disc list-inside text-lg text-primaryText space-y-4'>
          <li>Personalized support from dedicated specialists.</li>
          <li>Regular updates and transparent communication.</li>
          <li>Focus on your needs, so you can focus on patient care.</li>
          <li>Industry expertise that you can trust.</li>
        </ul>
      </section>
    </div>
  );
};

export default Services;
