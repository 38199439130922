import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='container mx-auto px-4 py-12'>
      <h1 className='text-4xl font-bold text-headingText mb-4'>Privacy Policy</h1>
      <p className='text-lg text-primaryText mb-8'>
        At Dr Cure LLC, your privacy is our priority. This Privacy Policy outlines how we collect, use, and protect your personal data, and explains your rights.
      </p>

      <section className='my-12 p-8 bg-white rounded-lg shadow-md'>
        <h2 className='text-3xl font-bold mb-4 text-headingText'>Agreement Overview</h2>
        <p className='text-lg text-primaryText'>
          These Terms govern your use of our website and services, establishing an agreement between Dr Cure LLC ("we," "us") and the user ("you").
        </p>
      </section>

      <section className='my-12 p-8 bg-white rounded-lg shadow-md'>
        <h2 className='text-3xl font-bold mb-4 text-headingText'>Links to Other Websites</h2>
        <p className='text-lg text-primaryText'>
          We are not responsible for the content of third-party sites linked from our website. Please review their privacy policies.
        </p>
      </section>

      <section className='my-12 p-8 bg-white rounded-lg shadow-md'>
        <h2 className='text-3xl font-bold mb-4 text-headingText'>Communication</h2>
        <p className='text-lg text-primaryText'>
          We may contact you via SMS, email, or phone for updates or promotions. You can opt out at any time by replying "STOP."
        </p>
      </section>

      <section className='my-12 p-8 bg-white rounded-lg shadow-md'>
        <h2 className='text-3xl font-bold mb-4 text-headingText'>Commitment to Privacy</h2>
        <p className='text-lg text-primaryText'>
          We prioritize your privacy and explain here how we collect, use, and protect your personal data.
        </p>
      </section>

      <section className='my-12 p-8 bg-white rounded-lg shadow-md'>
        <h2 className='text-3xl font-bold mb-4 text-headingText'>Information We Collect</h2>
        <p className='text-lg text-primaryText'>
          Personal data such as your name, email, and phone number may be collected when you provide it voluntarily (e.g., forms, emailing us).
        </p>
      </section>

      <section className='my-12 p-8 bg-white rounded-lg shadow-md'>
        <h2 className='text-3xl font-bold mb-4 text-headingText'>How We Use Your Information</h2>
        <ul className='list-disc list-inside text-lg text-primaryText space-y-4'>
          <li>Enhance your experience on our site.</li>
          <li>Respond to inquiries and provide services.</li>
          <li>Send you promotional materials if opted in.</li>
        </ul>
      </section>

      <section className='my-12 p-8 bg-white rounded-lg shadow-md'>
        <h2 className='text-3xl font-bold mb-4 text-headingText'>Data Security</h2>
        <p className='text-lg text-primaryText'>
          While we implement security measures, we cannot guarantee the complete security of data transmitted over the internet.
        </p>
      </section>

      <section className='my-12 p-8 bg-white rounded-lg shadow-md'>
        <h2 className='text-3xl font-bold mb-4 text-headingText'>Your Rights</h2>
        <p className='text-lg text-primaryText'>
          You can access, correct, or delete your personal data and opt out of promotional messages by replying "STOP" or unsubscribing via email.
        </p>
      </section>

      <section className='my-12 p-8 bg-white rounded-lg shadow-md'>
        <h2 className='text-3xl font-bold mb-4 text-headingText'>Sharing of Information with Third Parties</h2>
        <p className='text-lg text-primaryText'>
          We do not share mobile numbers or text messaging consent with third parties for marketing. Consent is exclusive to Dr Cure LLC.
        </p>
      </section>

      <section className='my-12 p-8 bg-white rounded-lg shadow-md'>
        <h2 className='text-3xl font-bold mb-4 text-headingText'>Exclusions for Text Messaging Data</h2>
        <p className='text-lg text-primaryText'>
          Text messaging opt-in data and consent will not be shared or used by third parties.
        </p>
      </section>

      <section className='my-12 p-8 bg-white rounded-lg shadow-md'>
        <h2 className='text-3xl font-bold mb-4 text-headingText'>Changes to this Policy</h2>
        <p className='text-lg text-primaryText'>
          We may update this policy, and changes will be posted here with an updated "Last Revised" date.
        </p>
      </section>

      <section className='my-12 p-8 bg-white rounded-lg shadow-md'>
        <h2 className='text-3xl font-bold mb-4 text-headingText'>Contact Us</h2>
        <p className='text-lg text-primaryText'>
          For any questions about this policy or your personal data, please contact us.
        </p>
      </section>

      <footer className='text-center mt-8 text-lg text-secondaryText'>
        <p>Last Updated: November 01, 2024</p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
