import React from 'react';
import { Link } from 'react-router-dom';

const Error = () => {
  return (
    <div className='flex flex-col items-center justify-center h-screen bg-primaryBackground text-white'>
      <h1 className='text-6xl font-bold mb-4 text-headingText'>404</h1>
      <p className='text-xl mb-8 text-primaryText'>Oops! The page you're looking for doesn't exist.</p>
      <Link to="/" className='px-6 bg-btn border-2 border-btnBorder text-white py-3 rounded hover:bg-btnHoverBg transition duration-300'>
        Go Back Home
      </Link>
    </div>
  );
};

export default Error;
