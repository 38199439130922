import React from 'react';
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin } from 'react-icons/fa';
import { MdOutlinePhone, MdOutlineEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className='bg-headingText text-white py-8'>
      <div className='container mx-auto px-4'>
        <div className='flex flex-col md:flex-row justify-between'>
          {/* Contact Information */}
          <div className='mb-4 md:mb-0'>
            <h2 className='text-xl font-bold mb-2'>Contact Us</h2>
            <p className='flex items-center'>
              <MdOutlinePhone className='mr-2' /> 
              <a href="tel:+14079901813" className='hover:underline text-white'>
                +1 (407) 990-1813
              </a>
            </p>
            <p className='flex items-center'>
              <MdOutlineEmail className='mr-2' /> 
              <a href="mailto:info@drcure.us" className='hover:underline text-white'>
                info@drcure.us
              </a>
            </p>
          </div>

          {/* Social Media Links */}
          <div className='flex space-x-4'>
            <a href="https://www.facebook.com/profile.php?id=61569065765233" className='hover:text-btnHoverBg' target='_blank'>
              <FaFacebookSquare className='text-2xl' />
            </a>
            <a href="https://www.instagram.com/drcurellc/" className='hover:text-btnHoverBg' target='_blank'>
              <FaInstagramSquare className='text-2xl' />
            </a>
            <a href="https://www.linkedin.com/company/dr-cure-llc/" className='hover:text-btnHoverBg' target='_blank'>
              <FaLinkedin className='text-2xl' />
            </a>
          </div>
        </div>

        {/* Copyright */}
        <div className='mt-8 text-center'>
          <p className='text-sm'>
            &copy; {new Date().getFullYear()} Dr Cure LLC. All rights reserved. <Link to='/privacy-policy' className='underline'> Privacy Policy</Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
