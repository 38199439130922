import React, { useState } from 'react';

const Contact = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setAlertMessage('Message sent successfully!');
    e.target.reset(); // Clear the form fields
  };

  const faqs = [
    {
      question: "What is CAQH?",
      answer: "CAQH stands for Council of Affordable and Quality Healthcare. It is a nonprofit that was created several years ago by the private insurance panels..."
    },
    {
      question: "Do I get to choose the Insurance panels I want to be on?",
      answer: "Yes. When you sign up for credentialing with us, you get to choose exactly which panels you want, and don’t want..."
    },
    {
      question: "How can I track my medical credentialing progress?",
      answer: "Your Credentialing Specialist will reach out at regular scheduled intervals to provide personalized updates..."
    },
    {
      question: "What if I don’t know which panels in my area are best for me?",
      answer: "We can help! One of our credentialing specialists will talk with you by phone and can help you to select the panels in your area that will be the best fit for you and your practice."
    },
    {
      question: "Am I guaranteed to get on the insurance panels I choose?",
      answer: "If you are fully licensed, we should have no problem identifying plenty of insurance companies and third-party payers for you to be credentialed with..."
    },
    {
      question: "What if the insurance panel I want to be credentialed with is closed?",
      answer: "Finding out a panel is closed can be frustrating. However, sometimes when panels say they are closed, they are still accepting providers..."
    },
    {
      question: "When do Credentialing and Recredentialing occur?",
      answer: "Credentialing happens before a provider is considered eligible to participate in an insurance network..."
    },
    {
      question: "Which is best EMR or EHR?",
      answer: "An EMR (electronic medical record) is a digital version of a chart with patient information stored in a computer..."
    },
    {
      question: "What is the definition of meaningful use?",
      answer: "Meaningful Use is defined as the use of certified electronic health record or EHR software in practices, hospitals..."
    },
    {
      question: "What is MACRA and MIPS?",
      answer: "MACRA combines parts of the Physician Quality Reporting System (PQRS), Value-based Payment Modifier (VBM), and the Medicare Electronic Health Record (EHR) incentive program into one single program called the Merit-based Incentive Payment System, or 'MIPS'."
    },
    {
      question: "What's the difference between HMO and PPO?",
      answer: "HMO stands for Health Maintenance Organization, and the coverage restricts patients to a particular group of physicians called a network..."
    },
  ];

  return (
    <div className="container mx-auto px-4 py-12">
      {/* Get in Touch Section */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-headingText mb-4">Get in Touch!</h1>
        <p className="text-lg text-primaryText">
          We'd love to hear from you! Whether you have a question, want to start a project, or just want to connect, feel free to reach out to us.
        </p>
      </div>

      {/* Contact Form Section */}
      <section className="my-12 p-8 bg-white rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-4 text-headingText">Contact Form</h2>
        {alertMessage && (
          <div className="mb-4 p-2 bg-green-100 text-green-800 border border-green-300 rounded">
            {alertMessage}
          </div>
        )}
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <label className="block text-primaryText mb-2">Name</label>
            <input type="text" className="w-full px-4 py-2 border rounded-md" placeholder="Your Name" required />
          </div>
          <div>
            <label className="block text-primaryText mb-2">Email</label>
            <input type="email" className="w-full px-4 py-2 border rounded-md" placeholder="Your Email" required />
          </div>
          <div>
            <label className="block text-primaryText mb-2">Message</label>
            <textarea className="w-full px-4 py-2 border rounded-md" placeholder="Your Message" rows="4" required></textarea>
          </div>
          <button type="submit" className="px-6 bg-btn border-2 border-btnBorder text-white py-3 rounded hover:bg-btnHoverBg transition duration-300">
            Submit
          </button>
        </form>
      </section>

      {/* Contact Information Section */}
      <section className="my-12 p-8 bg-white rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-4 text-headingText">Contact Information</h2>
        <p className="text-lg text-primaryText mb-2">
          <strong>Number:</strong> <a href="tel:+14079901813" className="text-primary hover:underline">+1 (407) 483 1319</a>
        </p>
        <p className="text-lg text-primaryText mb-2">
          <strong>Email:</strong> <a href="mailto:info@drcure.us" className="text-primary hover:underline">info@drcure.us</a>
        </p>
        <p className="text-lg text-primaryText"><strong>Address:</strong> <a href="https://maps.app.goo.gl/JgovMm1FDLWSkaHu6" className="text-primary hover:underline">2814 Silver Star Rd, Orlando, Florida 32808, US</a></p>
      </section>

      {/* FAQ Section */}
      <section className="my-12 p-8 bg-white rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-4 text-headingText">Frequently Asked Questions</h2>
        {faqs.map((faq, index) => (
          <div key={index} className="mb-4">
            <h3
              className={`text-xl font-bold text-secondaryText mb-2 cursor-pointer border-b-2 border-gray-300 pb-2 ${openIndex === index ? 'text-primary' : ''}`}
              onClick={() => toggleAccordion(index)}
            >
              {faq.question}
            </h3>
            {openIndex === index && (
              <div className="mt-2 p-4 border border-gray-300 rounded-md bg-gray-50">
                <p className="text-lg text-primaryText">{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </section>
    </div>
  );
};

export default Contact;
