import React from 'react';

const About = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      {/* Heading Section */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-headingText mb-4">
          A Team of Qualified Medical Professionals
        </h1>
        <p className="text-lg text-primaryText">
          We strive to provide the best services in credentialing and billing to support healthcare providers.
        </p>
      </div>

      {/* Vision Section */}
      <section className="my-12 p-8 bg-white rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-4 text-headingText">
          Our Vision
        </h2>
        <p className="text-lg text-primaryText">
          Our envisage to be a national leader in the credentialing (enrollment) and billing industry AND To be the ‘partner of choice’ for Healthcare companies and medical practices.
        </p>
      </section>

      {/* Mission Section */}
      <section className="my-12 p-8 bg-white rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-4 text-headingText">
          Our Mission
        </h2>
        <p className="text-lg text-primaryText">
          Our mission is to fight for every ($) dollar the provider deserves. Our goal is to complete the clients’ credentialing, enrollment, and billing processes allowing the physicians to focus on healthcare delivery for their patients by responding efficiently to the growing demands for credentialing and billing requirements. We focus on the paperwork to give you time to focus on patient care.
        </p>
      </section>

      {/* Services Privileges Section */}
      <section className="my-12 p-8 bg-white rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-4 text-headingText">
          Our Services Come With Certain Following Privileges
        </h2>
        <ul className="list-disc list-inside text-lg text-primaryText space-y-4">
          <li>
            You will be assigned an onboarding specialist/manager. Your manager will handle all aspects of the application and paperwork with insurance providers and government agencies and keep track of progress on your behalf.
          </li>
          <li>
            You will receive regular updates on the status of your case through comprehensive progress reports.
          </li>
          <li>
            Your manager will maintain consistent monitoring of the CAQH database to ensure all information is accurate and up-to-date.
          </li>
          <li>
            Your dedicated manager’s objective is to advocate for maximum reimbursement for your medical services.
          </li>
        </ul>
      </section>

      {/* Values Section */}
      <section className="my-12 p-8 bg-white rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-4 text-headingText">
          Our Values
        </h2>
        <ul className="list-disc list-inside text-lg text-primaryText space-y-4">
          <li>
            <strong>Integrity:</strong> We are professionally and ethically honest to our clients and trust each other to follow through on what we say we are going to do.
          </li>
          <li>
            <strong>Teamwork:</strong> We work as a team so our clients can take advantage of each team member’s unique strengths to achieve their desired results.
          </li>
          <li>
            <strong>Customer Service:</strong> We provide exceptional customer service that increases our clients’ satisfaction and the customer experience.
          </li>
          <li>
            <strong>Commitment:</strong> We are personally and professionally committed to achieving ours and our clients’ ambitions and goals.
          </li>
          <li>
            <strong>Courage:</strong> Our team has the courage to do the right thing, take on challenging tasks, and work through our problems.
          </li>
        </ul>
      </section>
    </div>
  );
};

export default About;
